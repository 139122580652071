import lpAbi from '../config/abi/lp.json'
import Web3 from 'web3'
import store from '../store';

function poolLengthPOR() {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xBeaC956F4760b6f102d50d2a1e7513c543A32732');
        crossingOkContractInstance.methods.poolLength().call().then(res => {
            resolve(res)
        })
    })
}

//授权
function depositPOR(myAddress, pid, amount,transactionsDetail) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xBeaC956F4760b6f102d50d2a1e7513c543A32732');
        const gas = await  crossingOkContractInstance.methods.deposit(pid, amount).estimateGas({from:myAddress})
        crossingOkContractInstance.methods.deposit(pid, amount).send({ from: myAddress,gas,  gasPrice:parseInt(store.state.gasPrice) })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}


//获取个人信息
function userinfoPOR(myaddress, pid) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xBeaC956F4760b6f102d50d2a1e7513c543A32732');
        crossingOkContractInstance.methods.userInfo(pid, myaddress).call().then(res => {
            resolve(res)
        })
    })
}

//获得奖励
function pendingRewardPOR(pid, myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xBeaC956F4760b6f102d50d2a1e7513c543A32732');
        crossingOkContractInstance.methods.pendingSwan(pid, myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//取出
function withdrawPOR(myAddress, pid, amount,transactionsDetail) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xBeaC956F4760b6f102d50d2a1e7513c543A32732');
        const gas = await crossingOkContractInstance.methods.withdraw(pid, amount).estimateGas({from:myAddress})
        crossingOkContractInstance.methods.withdraw(pid, amount).send({ from: myAddress,gas,  gasPrice:parseInt(store.state.gasPrice) })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//领取奖励
function harvestPOR(myAddress, pid,transactionsDetail) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xBeaC956F4760b6f102d50d2a1e7513c543A32732');
        const gas = await crossingOkContractInstance.methods.harvest(pid).estimateGas({from:myAddress})
        crossingOkContractInstance.methods.harvest(pid).send({ from: myAddress,gas,  gasPrice:parseInt(store.state.gasPrice) })
        .on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

function poolRewardPOR(){
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.ethereum);
        let crossingOkContractInstance = new web3.eth.Contract(lpAbi, '0xBeaC956F4760b6f102d50d2a1e7513c543A32732');
        crossingOkContractInstance.methods.poolsReward().call().then(res => {
            resolve(res)
        })
    })
}

export default {
   
    poolLengthPOR,
    depositPOR,
    userinfoPOR,
    pendingRewardPOR,
    withdrawPOR,
    harvestPOR,
    poolRewardPOR
}