<template>
  <div class="miningPopup">
    <div class="headline">
      <span class="title"
            v-if="selectShow">{{ $t('lang.swap143') }} {{ currencyCharacter }}</span>
      <span class="title"
            v-else>{{ $t('lang.swap160') }} {{ currencyCharacter }}</span>
      <img class="close"
           src="@/assets/img/cancel.png"
           alt=""
           @click="changeSite">
    </div>
    <div class="currencys">
      <div class="detailedness">
        <div class="information">
          <span v-if="selectShow">{{ $t('lang.swap143') }}</span>
          <span v-else>{{ $t('lang.swap160') }}</span>
          <span>{{ $t('lang.swap4') }}{{(max)}}</span>
        </div>
        <div class="information">
          <van-field class="tokenInput"
                     v-model="pledgeNumber"
                     type="number"
                     @input="onInputPledgeNumber"
                     placeholder="0" />
          <div class="button"
               @click="toMax">
            <span>
              {{ $t('lang.swap6') }}
            </span>
          </div>
          <span class="quantity">{{currencyCharacter || 'PHO-USDT'}}</span>
        </div>
      </div>
      <div class="get"
           @click="toWeb">
        <span>{{$t('lang.swap161')}} {{currencyCharacter || 'PHO-USDT'}}</span>
      </div>
      <div class="button">
        <div class="cancel"
             @click="changeSite">
          <span>{{$t('lang.swap154')}}</span>
        </div>
        <div class="cancel affirm"
             :class="special?'special':''"
             @click="affirm">
          <span>{{$t('lang.swap90')}}</span>
        </div>
      </div>
      <!-- <div class="obtain">
        <span>获取SWAN-USDT</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { significantDigits } from '@/utils/format.js';
export default {
  props: ['selectShow', 'max', 'currencyCharacter'],
  data () {
    return {
      special: true,
      pledgeNumber: ''
    }
  },
  methods: {
    // 取消
    changeSite () {
      this.$emit('handleConfirm')
    },
    onInputPledgeNumber(){
      if(this.pledgeNumber == '') return this.pledgeNumber = 0 ;
         this.pledgeNumber = parseInt(this.pledgeNumber)
    },
    // 确认
    affirm () {
      if (this.pledgeNumber != '' && this.pledgeNumber != '0') {

        this.$emit('getMyAddress', this.pledgeNumber)
        this.pledgeNumber = ''
      } else {
        this.$toast(this.$t('lang.swap10'))
      }
    },

    toWeb () {
      this.$router.push({ name: 'Swap' });
    },
    // Max
    toMax () {
      this.pledgeNumber = parseInt(this.max)
    },
    significantDigits
  }
}
</script>

<style lang="less" scoped>
.miningPopup {
  width: 350px;
  border-radius: 20px;
}

.headline {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.3);
  padding: 30px 18px 20px;
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    color: #333;
    font-weight: bold;
  }
  .close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.currencys {
  padding: 0 18px 20px;
  .detailedness {
    background: #f8f7f7;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    padding: 8px 16px 8px 12px;
    margin: 28px 0 6px;
    .information {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333;

      .tokenInput {
        width: 50px;
        font-size: 12px;
        color: rgba(152, 232, 110, 1) !important;
        background: #f8f7f7 !important;
        border-radius: 10px;
        padding: 2px 4px !important;
      }
      .button {
        font-size: 16px;
        font-family: Segoe UI;
        font-weight: bold;
        color: var( --text-color);
        background: rgba(152, 232, 110, 1);
        border-radius: 16px;
        padding: 4px 12px;
      }
      &:first-child {
        margin-bottom: 12px;
      }
      .quantity {
        font-size: 12px;
        color: rgba(152, 232, 110, 1);
      }
    }
  }
  .get {
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(152, 232, 110, 1);
    margin: 6px 0 18px;
    span-decoration: underline;
  }
  .button {
    display: flex;
    justify-content: space-between;
    .cancel {
      width: 40%;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 600;

      color: rgba(152, 232, 110, 1);
      border: 1px solid rgba(152, 232, 110, 1);
      opacity: 1;
      border-radius: 109px;
      padding: 8px 0;
      text-align: center;
      cursor: pointer;
    }
    .affirm {
      color: #999999;
      background: #e1e1e1;
      border: 0;
    }
    .special {
      color: #313031;
      background: rgba(152, 232, 110, 1);
    }
  }
  .obtain {
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: rgba(152, 232, 110, 1);
    text-align: center;
    margin-top: 20px;
  }

  .percentage {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #666666;
    margin-top: 30px;
    .value {
      color: #333333;
      margin-top: 16px;
    }
    .percentage_left {
      flex: 1;
    }
    .percentage_right {
      flex: 1;
    }
  }
  .explain {
    font-size: 12px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #666666;
    margin-top: 20px;
  }
}
</style>