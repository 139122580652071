<template>
  <div class="kuangchi">
    <topbutton router="kuangchi" style="margin-left: 20px; margin-top: 20px;"></topbutton>
    <van-popup v-model:show="contentshow" round>
      <div class="contentshow">
        <div class="contentshow_title">
          <span>{{ $t('lang.d153') }}</span>
          <div @click="contentshow = false">
            <img src="@/assets/imgsnowball/x_icon.png" style="width: 14px; height: 14px" alt="" />
          </div>
        </div>
        <div class="contentshow_box">
          <span style="margin-bottom: 43px">{{ $t('lang.d154') }}</span>
          <span>{{ $t('lang.d155') }}</span>
        </div>
      </div>
    </van-popup>
    <div class="line1">
      <div class="l1_title">
        <span>CIRCLES</span>
        <span>“</span>
        <img src="@/assets/imgsnowball/webhao_icon.png" style="width: 44px; height: 44px" alt="" @click="contentshow = true" />
        <span>”</span>
      </div>
      <!-- <div class="l1_lin">
                <div style="color: #9999; font-size: 12px;">
                    <span>{{ $t('lang.d127') }}</span>
                    <span>{{ $t('lang.d128') }}</span>
                </div>
                <div style="color: var( --text-color); font-size: 20px;">
                    <span>{{ sdata.publish_amount }}</span>
                    <span>{{ sdata.production}} </span>
                </div>
            </div> -->
      <div class="l2_lin">
        <div class="l_box">
          <img src="@/assets/imgsnowball/k_qianbao.png" class="img" alt="" />
          <span class="button" style="color: #14f6fe" @click="tiqu">{{ $t('lang.d129') }}</span>
          <div class="l_main">
            <span style="color: rgba(248, 251, 255, 1); font-style: 14px">
              {{ $t('lang.d130') }}
            </span>
            <span style="color: rgba(152, 232, 110, 1); font-size: 20px">
              {{ sdata.usable || 0.00}}
            </span>
          </div>
        </div>
        <div class="l_box">
          <img src="@/assets/imgsnowball/k_gon.png" class="img" alt="" />
          <span class="button" style="color: var( --text-color)" @click="tofenxiang">{{ $t('lang.d131') }}</span>
          <div class="l_main">
            <span style="color: rgba(248, 251, 255, 1); font-style: 14px">
              {{ $t('lang.d132') }}
            </span>
            <span style="color: rgba(152, 232, 110, 1); font-size: 20px">
              {{ sdata.share_num || 0 }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="line2">
      <div class="l2_title">{{ $t('lang.d133') }}</div>
      <div class="pos">
        <img src="@/assets/imgsnowball/wwq.png" alt="" style="width: 100%" class="l2_img1" v-if="staus == 0" />
        <img src="@/assets/imgsnowball/wwq.gif" alt="" style="width: 100%" class="l2_img1" v-else />
        <!-- <img src="@/assets/imgsnowball/wwa.gif" alt="" class="l2_img2"> -->
      </div>
      <div class="timer">
        <div class="t_con">
          <div>
            <span class="bum">{{ tim }}</span>
            <span class="tex">{{ $t('lang.d134') }}</span>
          </div>
          <div>
            <span class="bum">{{ min }}</span>
            <span class="tex">{{ $t('lang.d135') }}</span>
          </div>
          <div>
            <span class="bum">{{ miao }}</span>
            <span class="tex">{{ $t('lang.d136') }}</span>
          </div>
        </div>
      </div>
      <div class="qidon x" v-if="staus == 1">
        {{ $t('lang.d137') }}
      </div>
      <div class="qidon" @click="qidonwafun" v-else>
        {{ $t('lang.d138') }}
      </div>
    </div>
  </div>
</template>

<script>
import topbutton from '@/components/topbutton.vue';
import Web3 from 'web3';
import { mapState } from 'vuex';
import { kuangchi } from '@/api/index';
export default {
  name: '',
  components: {topbutton},
  data() {
    return {
      times: null,
      timer: null,
      sdata: {},
      staus: 0,
      tim: 0,
      min: 0,
      miao: 0,
      contentshow: false,
    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
    staus() {
      if (this.staus == 1) {
        this.timefun();
      }
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
    this.timer = null;
  },
  destroyed() {
    window.clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    async init() {
      if(this.account){
        await this.gethuizon();
        await this.getwakuanginfo();
      }
     
    //   window.setTimeout(() => {}, 1000);
    },
    timefun() {
      
      if (this.staus == 1) {
        let sum = 0
        this.timer = window.setInterval(() => {
          sum++;
          this.countDown(this.times);
       
           if(sum%10 == 0){
            this.gethuizon();
           }
        
          if (this.times < 0) {
            clearInterval(this.timer);

            this.getwakuanginfo();
            this.gethuizon();
          }
        }, 1000);
      }
    },
    tiqu() {
      this.$toast(this.$t('lang.swap65'));
    },
    tofenxiang() {
      this.$router.push('fenxiang');
    },
    countDown(time) {
      // var nowTime = +new Date(); //获取当前总毫秒数
      // var inputTime = +new Date(time); //用户输入的总毫秒数
      // times = (inputTime - nowTime) / 1000; //剩余的总秒数
      //转换为天
      this.times -= 1;
      var d = parseInt(time / 60 / 60 / 24);
      //转换为时
      var h = parseInt((time / 60 / 60) % 24);
      //设置成时钟格式 00：00：00 利用三元运算符
      h = h < 10 ? '0' + h : h;
      //转换为分
      var m = parseInt((time / 60) % 60);
      m = m < 10 ? '0' + m : m;
      //转换成秒
      var s = parseInt(time % 60);
      s = s < 10 ? '0' + s : s;
      this.tim = h;
      this.min = m;
      this.miao = s;
      // return d + "天 " + h + ":" + m + ":" + s;
    },
    gethuizon() {
      kuangchi.getminersummary(this.account).then(res => {
      
        this.sdata = res.data || {};
      });
    },
    getwakuanginfo() {
      kuangchi.getminerinfo(this.account).then(res => {
       
        this.times = res.data.countdowns;
        this.staus = res.data.status;
      });
    },

    async qidonwafun() {
      this.$toast(this.$t('lang.h133'))
       return;
      let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Start Miner'), this.account);
   
      let data = {
        signature: sign,
      };
      await kuangchi.postminerstart(data, this.account).then(res => {
      
        this.$toast(res.msg);
        if (res.code == 200) {
          this.getwakuanginfo();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.contentshow {
  width: 335px;
  .contentshow_title {
    padding: 20px;
    background: linear-gradient(180deg, rgba(232, 239, 255, 1) 0%, rgba(232, 239, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    font-size: 16px;
    color: rgba(34, 34, 34, 1);
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 21px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contentshow_box {
    padding: 20px;
    padding-bottom: 40px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(34, 34, 34, 1);
    display: flex;
    flex-direction: column;
  }
}
.kuangchi {
padding-top: 50px;
  width: 100%;
  background: rgba(16, 20, 32, 1);

  .line1 {
    width: 100%;
    padding: 20px;

    .l1_title {
      color: rgba(152, 232, 110, 1);
      width: 100%;
      font-size: 28px;
      font-weight: 700;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
      margin-bottom: 4rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .l1_lin {
      margin-top: 20px;
      border: 1px solid #56606b;
      height: 95px;
      border-radius: 16px;
      opacity: 1;
      background: #26272b;
      background: linear-gradient(90deg, #26272e 2%, #26272e 100%);
      padding: 20px 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    .l2_lin {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 111px;
      margin-top: 45px;

      .l_box {
        width: 48%;
        height: 100%;
        position: relative;
        border-radius: 8px;
        border: 1px solid rgba(36, 40, 52, 1);
        opacity: 1;
        background: rgba(36, 40, 52, 1);

        .img {
          width: 88px;
height: 82px;

          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .button {
          position: absolute;
          right: 8px;
          top: 8px;
          font-size: 14px;
          font-weight: 500;
          font-family: 'PingFang';
        }
        .l_main {
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 100%);
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .line2 {
    border-radius: 30px 30px 0 0;
    opacity: 1;
    background: rgba(27, 31, 46, 1);
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    .pos {
      width: 100%;
      height: 300px;

      position: relative;
      .l2_img1 {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
      .l2_img2 {
        position: absolute;
        left: 0px;
        top: 100px;
        width: 300px;
        height: 300px;
      }
    }
    .l2_title {
      color: #fff;

      font-size: 20px;
      font-weight: 700;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
    }
    .timer {
      width: 100%;
      display: flex;
      justify-content: center;
      .t_con {
        display: flex;
        width: 37%;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          align-items: center;
        }
        .bum {
          color: rgba(152, 232, 110, 1);
          font-size: 20px;
          font-weight: 500;
          font-family: 'HarmonyOSSansSC';
        }
        .tex {
          color: rgba(176, 176, 176, 1);
          font-size: 12px;
          font-weight: 500;
          font-family: 'PingFang';
        }
      }
    }
    .qidon {
      margin-top: 20px;
      height: 60px;
      border-radius: 16px;
      opacity: 1;
      background: rgba(152, 232, 110, 1);
      color: var( --text-color);
      font-size: 16px;
      font-weight: 700;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
      line-height: 60px;
      margin-bottom: 60px;
    }
    .y {
    }
    .x {
      opacity: 0.2;
    }
  }
}
</style>